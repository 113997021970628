import React from 'react';

const PrivacyPolicyLike = () => {
  const styles = {
    container: {
      maxWidth: '800px',
      margin: 'auto',
      marginTop: '100px',
      padding: '20px',
      fontFamily: 'Arial, sans-serif',
      lineHeight: '1.6',
    },
    heading: {
      color: '#ffffff',
      fontSize: '24px',
      marginBottom: '10px',
    },
    subheading: {
      color: '#ffffff',
      fontSize: '20px',
      marginBottom: '8px',
    },
    paragraph: {
      color: '#ffffff',
      marginBottom: '15px',
    },
    listItem: {
      color: '#ffffff',
      marginBottom: '8px',
      listStyleType: 'none',
    },
    link: {
      color: '#ffffff',
      textDecoration: 'underline',
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Privacy Policy</h1>
      <p style={styles.paragraph}><strong>Last updated:</strong> June 15, 2024</p>
      <p style={styles.paragraph}>
        Welcome to likeAI, your premier Instagram caption and comment generator. We prioritize your privacy and are dedicated to protecting your personal information. This Privacy Policy explains the types of information we collect, how we use it, how we safeguard it, and your rights regarding your personal data.
      </p>

      <h2 style={styles.subheading}>1. Information We Collect</h2>
      <h3 style={styles.subheading}>1.1 Personal Information</h3>
      <p style={styles.paragraph}>We may collect the following personal information when you use our services:</p>
      <ul>
        <li style={styles.listItem}><strong>Name</strong></li>
        <li style={styles.listItem}><strong>Email address</strong></li>
        <li style={styles.listItem}><strong>Username</strong></li>
      </ul>

      <h3 style={styles.subheading}>1.2 Non-Personal Information</h3>
      <p style={styles.paragraph}>We also collect non-personal information which does not directly identify you. This includes:</p>
      <ul>
        <li style={styles.listItem}><strong>IP address</strong></li>
        <li style={styles.listItem}><strong>Browser type and version</strong></li>
        <li style={styles.listItem}><strong>Device information</strong></li>
        <li style={styles.listItem}><strong>Operating system</strong></li>
        <li style={styles.listItem}><strong>Pages visited on our website</strong></li>
        <li style={styles.listItem}><strong>Time and date of visits</strong></li>
      </ul>

      <h3 style={styles.subheading}>1.3 Content Data</h3>
      <p style={styles.paragraph}>
        When you input text or data into likeAI to generate captions or comments, we may collect and store this content to improve our services. Content is processed by AI models provided by <a href="https://www.openai.com" style={styles.link}>OpenAI</a> to generate personalized results.
      </p>

      <h2 style={styles.subheading}>2. How We Use Your Information</h2>
      <h3 style={styles.subheading}>2.1 Providing and Improving Our Services</h3>
      <ul>
        <li style={styles.listItem}>To process your caption and comment generation requests.</li>
        <li style={styles.listItem}>To personalize your experience on our platform.</li>
        <li style={styles.listItem}>To provide customer support.</li>
        <li style={styles.listItem}>To improve the quality and relevance of generated content.</li>
      </ul>

      <h3 style={styles.subheading}>2.2 Communication</h3>
      <ul>
        <li style={styles.listItem}>To send updates, newsletters, and promotional materials.</li>
        <li style={styles.listItem}>To respond to inquiries and feedback.</li>
      </ul>

      <h3 style={styles.subheading}>2.3 Analytics and Research</h3>
      <ul>
        <li style={styles.listItem}>To conduct data analysis for service improvement.</li>
        <li style={styles.listItem}>To monitor and analyze usage trends.</li>
      </ul>

      <h2 style={styles.subheading}>3. Sharing Your Information</h2>
      <h3 style={styles.subheading}>3.1 Service Providers</h3>
      <p style={styles.paragraph}>We may share your information with third-party providers who assist us in delivering and improving our services, including:</p>
      <ul>
        <li style={styles.listItem}>Email services</li>
        <li style={styles.listItem}>Analytics tools</li>
        <li style={styles.listItem}>Cloud storage solutions</li>
      </ul>

      <h3 style={styles.subheading}>3.2 Legal Requirements</h3>
      <p style={styles.paragraph}>We may disclose your information if required by law or in response to valid legal requests.</p>

      <h3 style={styles.subheading}>3.3 Business Transfers</h3>
      <p style={styles.paragraph}>In case of a merger, acquisition, or sale of assets, your information may be part of the transferred assets.</p>

      <h2 style={styles.subheading}>4. Security</h2>
      <p style={styles.paragraph}>
        We implement advanced measures to secure your personal information. However, no system is entirely secure, and we cannot guarantee absolute protection.
      </p>

      <h2 style={styles.subheading}>5. Your Rights</h2>
      <h3 style={styles.subheading}>5.1 Access and Correction</h3>
      <p style={styles.paragraph}>You can access and correct your personal data via your account settings or by contacting us directly.</p>

      <h3 style={styles.subheading}>5.2 Deletion</h3>
      <p style={styles.paragraph}>You can request data deletion by contacting us, subject to applicable laws.</p>

      <h3 style={styles.subheading}>5.3 Opt-Out</h3>
      <p style={styles.paragraph}>You can opt out of promotional communications by using the unsubscribe links or contacting us.</p>

      <h2 style={styles.subheading}>6. Cookies and Tracking Technologies</h2>
      <p style={styles.paragraph}>
        likeAI uses cookies to enhance user experience. Learn more about cookie usage and preferences in our <a href="#" style={styles.link}>Cookie Policy</a>.
      </p>

      <h2 style={styles.subheading}>7. Third-Party Links</h2>
      <p style={styles.paragraph}>
        Our website may include links to third-party sites. We are not responsible for their content or privacy practices. Please review their policies before providing any personal data.
      </p>

      <h2 style={styles.subheading}>8. Children's Privacy</h2>
      <p style={styles.paragraph}>
        likeAI is not intended for users under 18. If we identify that we have collected information from a user under 13, we will promptly delete it.
      </p>

      <h2 style={styles.subheading}>9. Changes to This Privacy Policy</h2>
      <p style={styles.paragraph}>
        We may revise this Privacy Policy periodically. Updates will be posted with an updated effective date. Regular review is recommended.
      </p>

      <h2 style={styles.subheading}>10. Contact Us</h2>
      <p style={styles.paragraph}>
        For questions or concerns about this Privacy Policy, contact us at:
      </p>
      <ul>
        <li style={styles.listItem}><strong>Email:</strong> <a href="mailto:admin@likeai.com" style={styles.link}>admin@likeai.com</a></li>
      </ul>

      <p style={styles.paragraph}>
        By using likeAI, you agree to this Privacy Policy. Thank you for trusting us to enhance your Instagram experience.
      </p>
    </div>
  );
};

export default PrivacyPolicyLike;
