import React from 'react';

const styles = {
  container: {
    maxWidth: '800px',
    margin: 'auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
    marginTop: '100px',
    color: '#ffffff',
  },
  heading1: {
    fontSize: '24px',
    marginBottom: '10px',
    color: '#ffffff',
  },
  date: {
    marginBottom: '15px',
  },
  paragraph: {
    marginBottom: '15px',
  },
  heading2: {
    fontSize: '20px',
    marginBottom: '8px',
  },
  list: {
    listStyleType: 'none',
    marginBottom: '15px',
  },
  listItem: {
    marginBottom: '10px',
  },
  contactList: {
    listStyleType: 'none',
    marginBottom: '15px',
  },
};

const TermsOfService = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.heading1}>Terms of Service</h1>
      <p style={styles.date}><strong>Last updated:</strong> Jul 16, 2024</p>
      <p style={styles.paragraph}>
        Welcome to LikeAI, your AI-powered solution for Instagram comments and captions. These Terms of Service ("Terms") govern your use of our website and services. By accessing or using LikeAI, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use our services.
      </p>

      <h2 style={styles.heading2}>1. Use of Our Services</h2>
      <p style={styles.paragraph}>
        You must follow all applicable policies and laws when using our services. We reserve the right to suspend or terminate access for non-compliance or suspected misconduct.
      </p>

      <h2 style={styles.heading2}>2. Eligibility</h2>
      <p style={styles.paragraph}>
        You must be at least 13 years old to use LikeAI. By accessing our platform, you represent and warrant that you meet this age requirement.
      </p>

      <h2 style={styles.heading2}>3. Your Account</h2>
      <p style={styles.paragraph}>
        Creating an account may be required for certain features. Keep your account secure and notify us of any unauthorized access. You are responsible for all activities under your account.
      </p>

      <h2 style={styles.heading2}>4. Data Usage for Analytics</h2>
      <p style={styles.paragraph}>
        By using LikeAI, you consent to the collection and use of anonymized data to enhance user experience, improve services, and develop features in compliance with our Privacy Policy.
      </p>

      <h2 style={styles.heading2}>5. Prohibited Activities</h2>
      <ul style={styles.list}>
        <li style={styles.listItem}>Violating laws or regulations.</li>
        <li style={styles.listItem}>Sharing content that is offensive, defamatory, or harmful.</li>
        <li style={styles.listItem}>Using generated content for harassment, misinformation, or malicious purposes.</li>
        <li style={styles.listItem}>Attempting unauthorized access to our systems.</li>
      </ul>

      <h2 style={styles.heading2}>6. Payments and Refunds</h2>
      <p style={styles.paragraph}>
        Certain features may require payment. Payments are non-refundable unless explicitly stated. We reserve the right to update pricing with notice.
      </p>

      <h2 style={styles.heading2}>7. Content Guidelines</h2>
      <p style={styles.paragraph}>
        Content generated by LikeAI should adhere to community guidelines and avoid explicit, hateful, or harmful language. Users are fully responsible for the content they generate and share.
      </p>

      <h2 style={styles.heading2}>8. Intellectual Property</h2>
      <p style={styles.paragraph}>
        LikeAI retains ownership of all platform-related intellectual property. Generated captions and comments are considered user-generated content.
      </p>

      <h2 style={styles.heading2}>9. Disclaimer</h2>
      <p style={styles.paragraph}>
        Our services are provided "as is" without warranties. We are not liable for any unintended consequences arising from the use of our platform.
      </p>

      <h2 style={styles.heading2}>10. Changes to Terms</h2>
      <p style={styles.paragraph}>
        We may update these Terms at any time. Continued use of LikeAI constitutes agreement to the revised Terms.
      </p>

      <h2 style={styles.heading2}>11. Contact Information</h2>
      <p style={styles.paragraph}>
        For questions or concerns, contact us at:
      </p>
      <ul style={styles.contactList}>
        <li style={styles.listItem}>Email: support@likeai.app</li>
        <li style={styles.listItem}>Address: 17/135 D near Bible College Church Ketti, Palada, 643215</li>
      </ul>
    </div>
  );
};

export default TermsOfService;
