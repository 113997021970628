import React from 'react';
import './Faq.css';

const Faq = () => {
  const questionsAndAnswers = [
    {
      question: "What is LikeAI?",
      answer: "LikeAI is an AI-powered tool that helps you generate engaging Instagram captions and comments effortlessly."
    },
    {
      question: "How does LikeAI work?",
      answer: "Simply enter a brief prompt or keyword, and LikeAI uses advanced AI models to generate captions or comments tailored to your content."
    },
    {
      question: "Is LikeAI free to use?",
      answer: "LikeAI offers a free model for basic features and provides daily credits to try out premium options."
    },
    {
      question: "Can I use captions generated by LikeAI for commercial posts?",
      answer: "Yes! All captions generated by LikeAI are copyright-free and can be used for personal or commercial purposes."
    },
    {
      question: "How are payments handled on LikeAI?",
      answer: "Payments are securely processed through Stripe, ensuring the safety of your financial information when purchasing credits."
    },
    {
      question: "Does LikeAI support multiple languages?",
      answer: "Absolutely! LikeAI supports caption and comment generation in multiple languages to cater to a global audience."
    }
  ];

  return (
    <div className="faq-container">
      <h2 style={{ color: "#fff" }}>Frequently Asked Questions</h2>
      <div className="faq-list">
        {questionsAndAnswers.map((item, index) => (
          <div key={index} className="faq-item">
            <div className="faq-question">
              {item.question}
            </div>
            <div className="faq-answer">
              {item.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faq;
